/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { PageProps, Post } from '../types'
import { PostExcert } from '../components/post-excerpt'

type DataProps = PageProps<{
  markdownRemark: Post
  next?: Post
  previous?: Post
}>

const BlogPostTemplate = ({ data, location }: DataProps): JSX.Element => {
  const post = data.markdownRemark
  const { previous, next } = data

  return (
    <Layout
      pages={data.pages}
      location={location}
      site={data.site}
      nav={[
        {
          href: `/categories/`,
          title: 'Categories'
        },
        {
          href: `/categories/${data.markdownRemark.frontmatter.categories[0]!.toLowerCase()}`,
          title: data.markdownRemark.frontmatter.categories[0]!
        },
        {
          href: data.markdownRemark.fields.slug,
          title: data.markdownRemark.frontmatter.title
        }
      ]}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <PostExcert post={post} site={data.site} />
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0
          }}>
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        description
        author {
          name
          summary
        }
      }
    }
    pages: allFile(
      filter: {
        sourceInstanceName: { eq: "pages" }
        childMarkdownRemark: { id: { glob: "*" } }
      }
    ) {
      nodes {
        childMarkdownRemark {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        categories
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
